<template>
  <div>
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-input
          color="dark"
          class="mx-1"
          type="text"
          size="small"
          label="Busca"
          v-model="filtro.busca"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data Até"
          v-model="filtro.dataAte"
        ></vs-input>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Empresa</label
          >
          <br />
          <el-select
            size="small"
            filterable
            clearable
            v-model="filtro.id_empresa"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="empresa in empresas"
              :value="empresa.id"
              :label="empresa.razao_social"
              :key="empresa.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Vendedor</label
          >
          <br />
          <el-select
            size="small"
            filterable
            clearable
            v-model="filtro.id_colaborador"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="vendedor in vendedores"
              :value="vendedor.id"
              :label="vendedor.nome"
              :key="vendedor.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Status</label
          >
          <br />
          <el-select
            size="small"
            filterable
            clearable
            v-model="filtro.id_status"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="status in nota_status"
              :value="status.id"
              :label="status.nome"
              :key="status.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
            >Tipo Pessoa</label
          >
          <br />
          <el-select
            filterable
            clearable
            size="small"
            :popper-append-to-body="false"
            v-model="filtro.tipo_pessoa"
            class="mx-1 mt-2"
          >
            <el-option
              v-for="tipo in tipo_pessoa"
              :value="tipo.value"
              :label="tipo.nome"
              :key="tipo.value"
            ></el-option>
          </el-select>
        </div>
        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="getNotas()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <div
      id="loadingNotasFinanceiro"
      class="cardTable mb-1 vs-con-loading__container"
    >
      <div v-if="notas.length == 0 || notas.length == undefined">
        <div class="pt-20 notasFinanceiro con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">Nenhuma Nota</h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="mt-1">
        <!-- <VuePerfectScrollbar class="notasFinanceiro" :settings="settings"> -->
        <vs-table
          max-items="10"
          pagination
          stripe
          :data="notas"
          maxHeight="68vh"
          id="tableNotas"
        >
          <template slot="thead">
            <vs-th sort-key="id_contrato" style="width: 5%" id="center"
              >Contrato</vs-th
            >
            <vs-th sort-key="rps" style="width: 5%" id="center">RPS</vs-th>
            <vs-th sort-key="numero" style="width: 5%" id="center">NOTA</vs-th>
            <vs-th sort-key="nome_fantasia" style="width: 12%">Cliente</vs-th>
            <vs-th sort-key="empresa" style="width: 12%">Empresa</vs-th>
            <vs-th sort-key="tipo_faturamento" style="width: 10%" id="center"
              >Tipo Contrato</vs-th
            >
            <vs-th sort-key="valor_liquido" style="width: 10%" id="center"
              >Valor Líquido</vs-th
            >
            <vs-th sort-key="valor_bruto" style="width: 10%" id="center"
              >Valor Bruto</vs-th
            >
            <vs-th sort-key="data_emissao" style="width: 10%" id="center"
              >Data Emissão</vs-th
            >
            <vs-th sort-key="status" style="width: 10%" id="center"
              >Status</vs-th
            >
            <vs-th sort-key="obs" style="width: 25%">Observação</vs-th>
            <vs-th style="width: 5%">Ações</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :style="
                tr.lixeira > 0
                  ? 'color: #dd3522 !important; background-color: #f5efef !important;'
                  : ''
              "
              :data="notas"
              :key="index"
              v-for="(tr, index) in data"
            >
              <vs-td
                :data="tr.id_contrato"
                class="text-center"
                style="font-size: 12px"
              >
                <a
                  target="_blank"
                  :href="'contrato?id=' + tr.id_contrato"
                  style="color: darkblue; cursor: pointer"
                >
                  <b>{{ tr.id_contrato }}</b>
                </a>
              </vs-td>
              <vs-td
                :data="tr.rps"
                class="text-center font-bold"
                style="font-size: 12px"
                >{{ tr.rps ? tr.rps : "Sem RPS" }}</vs-td
              >
              <vs-td
                :data="tr.numero"
                class="text-center font-bold"
                style="font-size: 12px"
                >{{ tr.numero ? tr.numero : "Sem Nota" }}</vs-td
              >
              <vs-td>
                <VisualizarCliente
                  fontSize="small"
                  @update="close()"
                  :id_cliente="tr.id_cliente"
                  :nomeCliente="tr.nome_fantasia"
                />
              </vs-td>
              <vs-td style="font-size: 10px">
                <b>{{ tr.empresa }}</b>
              </vs-td>

              <vs-td class="text-center" style="font-size: 12px">
                <b style="text-transform: uppercase !important">
                  {{ tr.tipo_faturamento }}
                </b>
              </vs-td>
              <vs-td class="text-center font-bold" style="font-size: 12px">
                {{ $currency(tr.valor_liquido) }}
              </vs-td>
              <vs-td class="text-center font-bold" style="font-size: 12px">
                {{ $currency(tr.valor_bruto) }}
              </vs-td>
              <vs-td class="text-center font-bold" style="font-size: 12px">{{
                tr.data_emissao ? tr.data_emissao : "Sem data"
              }}</vs-td>
              <vs-td class="text-center font-bold" style="font-size: 12px">{{
                tr.status ? tr.status : "Pendente"
              }}</vs-td>
              <vs-td style="font-size: 12px">{{
                tr.obs ? tr.obs : "Sem Observação"
              }}</vs-td>
              <vs-td style="display: flex">
                <a
                  target="_blank"
                  :href="tr.link"
                  style="cursor: pointer; text-decoration: none"
                >
                  <vs-button
                    icon="remove_red_eye"
                    radius
                    type="relief"
                    size="small"
                    color="success"
                  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- </VuePerfectScrollbar> -->
      </div>
    </div>
    <vx-card no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1"
          class
        >
          <vs-button
            @click="voltar()"
            icon-pack="feather"
            line-origin="left"
            color="#fff"
            type="line"
            icon="icon-arrow-left"
            size="small"
          >
            <span style="font-size: 14px">Voltar</span>
          </vs-button>
        </vs-col>
        <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
          <span style="color: white; font-size: 12px"
            >Notas: {{ totalNotas }}</span
          >
        </vs-col>
        <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <span style="color: white; font-size: 12px"
            >Total liquido: {{ $currency(totalValor) }}</span
          >
        </vs-col>
        <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <span style="color: white; font-size: 12px"
            >Total bruto: {{ $currency(totalValorBruto) }}</span
          >
        </vs-col>
        <vs-col
              vs-w="2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
            >
              <vs-button
                icon="get_app"
                type="line"
                @click="exportToExcel()"
                color="#FFCA00"
                size="small"
                ><b style="font-size: 16px">Excel</b></vs-button
              >
            </vs-col>
        <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >
          <formaPagamentoTotais :totais="totais"></formaPagamentoTotais>
        </vs-col>
      </vs-row>
    </vx-card>
    <vx-card no-shadow card-background="danger">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center"
          >&nbsp;</vs-col
        >
        <vs-col vs-w="2" vs-type="flex" vs-justify="center" vs-align="center">
          <span style="color: #fff; font-size: 12px" class="font-bold"
            >Notas Canceladas: {{ totalNotasCancel }}</span
          >
        </vs-col>
        <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <span style="color: #fff; font-size: 12px" class="font-bold"
            >bruto cancelado: {{ $currency(totalValorBrutoCancel) }}</span
          >
        </vs-col>
        <vs-col
          vs-w="2"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <span style="color: #fff; font-size: 12px" class="font-bold"
            >liquido cancelado: {{ $currency(totalValorCancel) }}</span
          >
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import VisualizarCliente from "./../../../cliente/visualizar.vue";
import formaPagamentoTotais from "./components/formaPagamentoTotais";
export default {
  data() {
    return {
      tipo_pessoa: [
        { value: 1, nome: "PESSOA FÍSICA" },
        { value: 2, nome: "PESSOA JURÍDICA" },
      ],
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1.5,
        height: 1000,
      },
      vendedores: {},
      notas: {},
      filtro: {
        id_status: null,
        id_empresa: null,
        data_emissao: this.$formartData.dataFiltro(),
        dataHoje: this.$formartData.dataFiltro(),
      },
      empresas: {},
      nota_status: {},
      totais: [],
    };
  },
  methods: {
    async getNotas() {
      await this.$vs.loading({
        container: "#loadingNotasFinanceiro",
        scale: 0.6,
      });
      try {
        const res = await this.$http.post(`getNotas`, this.filtro);
        this.notas = res.notas;

        this.totalNotas = this.notas.reduce(function (total, nota) {
          return nota.lixeira != 1 ? total + 1 : total + 0;
        }, 0);
        this.totalNotasCancel = this.notas.reduce(function (total, nota) {
          return nota.lixeira == 1 ? total + 1 : total + 0;
        }, 0);

        this.totalValor = this.notas.reduce(function (total, nota) {
          return nota.lixeira != 1 ? total + nota.valor_liquido : total + 0;
        }, 0);
        this.totalValorCancel = this.notas.reduce(function (total, nota) {
          return nota.lixeira == 1 ? total + nota.valor_liquido : total + 0;
        }, 0);
        this.totalValorBruto = this.notas.reduce(function (total, nota) {
          return nota.lixeira != 1 ? total + nota.valor_bruto : total + 0;
        }, 0);
        this.totalValorBrutoCancel = this.notas.reduce(function (total, nota) {
          return nota.lixeira == 1 ? total + nota.valor_bruto : total + 0;
        }, 0);
        this.totais = res.totais;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#loadingNotasFinanceiro > .con-vs-loading"
        );
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getVendedores() {
      try {
        this.vendedores = await this.$http.get(`colaborador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getNotaStatus() {
      try {
        this.nota_status = await this.$http.get(`nota_status`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async voltar() {
      this.$router.push("/financeiro");
    },
    async exportToExcel() {
      await this.$vs.loading();
      try {

        import("@/vendor/Export2Excel").then(async (excel) => {
          let headerTitle = [
            "Contrato",
            "RPS",
            "Nota",
            "Cliente",
            "Empresa",
            "Tipo Contrato",
            "Valor Liquido",
            "Valor Bruto",
            "Data Emissão",
            "Status",
            "Observação"
          ];
          let headerVal = [
            "id_contrato",
            "rps",
            "numero",
            "nome_fantasia",
            "empresa",
            "tipo_faturamento",
            "valor_liquido",
            "valor_bruto",
            "data_emissao",
            "status",
            "obs"
          ];
          const data = await this.formatJson(headerVal, this.notas);
          const hoje = await this.$formartData.formatar(new Date());
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: `Notas_Excel_${hoje}`,
            autoWidth: true,
            bookType: "xlsx",
          });
        });
        this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close();
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
  async mounted() {
    if (this.$route.query.cliente > 0) {
      this.filtro.id_cliente = this.$route.query.cliente;
    }
    if (this.$route.query.contrato > 0) {
      this.filtro.id_contrato = this.$route.query.contrato;
    }
    await this.getNotas();
    await this.getEmpresas();
    await this.getVendedores();
    await this.getNotaStatus();
  },
  components: {
    ...components,
    VisualizarCliente,
    formaPagamentoTotais,
  },
};
</script>
<style lang="scss">
.notasFinanceiro {
  height: 73vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
