<template>
  <div>
    <vs-button icon="loupe" color="#3da4fd" type="line" @click="openModal()" size="small">
      <b style="font-size: 14px">Forma Pagamento</b>
    </vs-button>
    <vs-popup v-if="open" title="Totais Forma Pagamento" :active.sync="modal">
      <div id="totais" class="mb-1 vs-con-loading__container">
        <div v-if="totais.length == 0">
          <div class="pt-20 scroll-forma-pagamento-totais con-colors">
            <ul class="pt-20">
              <li class="danger-box">
                <h2 class="p-5" style="color: white !important">Nenhuma Forma de Pagamento</h2>
              </li>
            </ul>
          </div>
        </div>
        <div v-else class="mt-1">
          <VuePerfectScrollbar class="scroll-forma-pagamento-totais" :settings="settings">
            <vs-table class="px-2" stripe :data="totais" max-items="100" id="tableCliente">
              <template slot="thead">
                <vs-th sort-key="id_forma_pagamento" style="width: 20%">Forma de Pagamento</vs-th>
                <vs-th sort-key="saldo_inicial" style="width: 15%" id="center">Total</vs-th>
                <vs-th sort-key="saldo_inicial" style="width: 15%" id="center">Valor</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(tr, index) in data">
                  <vs-td :data="tr.id_forma_pagamento">{{ tr.forma_pagamento }}</vs-td>
                  <vs-td :data="tr.quantidade" class="text-center">
                    <b>{{ tr.quantidade }}</b>
                  </vs-td>
                  <vs-td :data="tr.quantidade" class="text-center">
                    <b>{{ $currency(tr.valor) }}</b>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </VuePerfectScrollbar>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    totais: {}
  },
  data() {
    return {
      open: false,
      modal: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      }
    };
  },
  methods: {
    async openModal() {
      this.open = await true;
      this.modal = await true;
    }
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
.scroll-forma-pagamento-totais {
  height: 55vh;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
